import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const getMobileNumber = async phoneNumber => {
  const number = parsePhoneNumberFromString(phoneNumber, 'GB');
  return number.isValid() && number.getType() === 'MOBILE' ? number : false;
};

export const isValidEmail = email => {
  const regex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$');
  const validInput = regex.test(email);
  return validInput;
};

const flattenForm = parsedJSON => {
  const { sections } = parsedJSON;
  const flattenedForm = {};
  let navigationOrder = 1;

  sections.sort((a, b) => (a.sectionNumber > b.sectionNumber ? 1 : -1));

  sections.forEach(section => {
    const { steps } = section;

    steps.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));

    steps.forEach(step => {
      flattenedForm[step.key] = {
        ...step,
        sectionKey: section.key,
        sectionTitle: section.title,
        navigationOptions: { title: section.title, inStack: step.backButtonMode === 'previous' },
        navigationOrder,
      };

      navigationOrder += 1;
    });
  });

  return flattenedForm;
};

const formatCurrency = (amount, denomination) => {
  const sign = amount < 0 ? '-' : '';
  let _amount = null;

  switch (denomination) {
    case 'pounds':
      _amount = amount;
      break;
    case 'pence':
    default:
      _amount = amount / 100;
      break;
  }

  const formatted = _amount
    .toString()
    .replace('-', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${sign}£${formatted}`;
};

export const parseIntoPence = value => {
  const floatValue = parseFloat(value).toFixed(2);
  return floatValue * 100;
};

export const agreementProcessEnum = Object.freeze({ BORROW: 'borrow', JOIN: 'join', REGISTRATION: 'registration' });

export const filterUserAgreements = (userAgreements, agreementEnum) => {
  return Object.values(userAgreements)
    .filter(agreement => agreement.processKeys.includes(agreementEnum))
    .map(({ title, value, key }) => {
      return { title, value, key };
    });
};

export function centerPopupWindow(url, width, height) {
  const leftPosition = window.screen.width / 2 - (width / 2 + 10);
  const topPosition = window.screen.height / 2 - (height / 2 + 50);
  window.open(
    url,
    'Window2',
    'status=no,height=' +
      height +
      ',width=' +
      width +
      ',resizable=yes,left=' +
      leftPosition +
      ',top=' +
      topPosition +
      ',screenX=' +
      leftPosition +
      ',screenY=' +
      topPosition +
      ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no',
  );
}

export default {
  getMobileNumber,
  isValidEmail,
  parseIntoPence,
  flattenForm,
  formatCurrency,
  agreementProcessEnum,
  filterUserAgreements,
  centerPopupWindow,
};
