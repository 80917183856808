import React from 'react';
import { StyledButton } from '../design-system';

const StandardButton = ({ as, bg, text, width, onClick, textAlign, margin, fontSize, color, buttonDisplay }) => {
  return (
    <StyledButton
      onClick={onClick}
      bg={bg}
      as={as}
      width={width}
      textAlign={textAlign}
      mt={margin}
      fontSize={fontSize}
      color={color}
      display={buttonDisplay}
    >
      {text}
    </StyledButton>
  );
};

export default StandardButton;
