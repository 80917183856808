import { getUser } from './authClient';

const bootstrapAppData = async () => {
  const user = await getUser();
  if (!user) {
    return { user: null };
  }
  const { currentUser } = user;
  return { user: currentUser };
};

export default bootstrapAppData;
