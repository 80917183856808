import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Noto Sans:300,400,700', 'Roboto:300,400,700', 'sans-serif'],
  },
});

export const defaultTheme = {
  breakpoints: ['40em', '52em', '64em', '80em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 18, 20, 24, 36, 48, 80, 96],
  headingSizes: [24, 36, 48, 64, 96],
  fontWeights: [400, 700],
  sizes: {
    divider: [1, 2, 3, 4],
  },
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    serif: 'Titillium, georgia, times, serif',
    sansSerif: 'Noto Sans,  BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, noto, "segoe ui", arial, sans-serif',
    bodyFonts: 'Noto Sans,  BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, noto, "segoe ui", arial, sans-serif',
    headingFonts: 'Noto Sans,  BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, noto, "segoe ui", arial, sans-serif',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 16, 9999, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    oliveDrab: '#72A826',
    scarletGum: '#391354',
    primary: '#72A826',
    secondary: '#391354',
    lightGrey: '#f7f7f7',
    mediumGrey: '#d3d3d3',
    gray: '#818181',
    border: '#dcdcdc',
    black: '#000',
    nearBlack: '#363636',
    darkGray: '#333',
    midGray: '#555',
    silver: '#999',
    lightSilver: '#aaa',
    moonGray: '#ccc',
    lightGray: '#eee',
    nearWhite: '#f4f4f4',
    white: '#fff',
    transparent: 'transparent',
    textColor: '#363636',
    blacks: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)',
    ],
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
    buttons: {
      primary: {
        color: 'white',
        bg: 'red',
      },
      secondary: {
        color: 'white',
        bg: 'tomato',
      },
    },

    // ... and so on
  },
};

export const soarTheme = { ...defaultTheme, name: 'Soar', colors: { ...defaultTheme.colors, primary: '#72A826', secondary: '#391354' } };
export const gcuTheme = { ...defaultTheme, name: 'GCU', colors: { ...defaultTheme.colors, primary: '#00587c', secondary: '#00a8dd' } };
export const wishawTheme = { ...defaultTheme, name: 'Wishaw', colors: { ...defaultTheme.colors, primary: '#00853F', secondary: '#004A64' } };
export const pioneerTheme = { ...defaultTheme, name: 'Pioneer', colors: { ...defaultTheme.colors, primary: '#067f8f', secondary: '#009aa7' } };
export const capitalTheme = { ...defaultTheme, name: 'Capital', colors: { ...defaultTheme.colors, primary: '#79A6DB', secondary: '#021D49' } };
export const discoveryTheme = { ...defaultTheme, name: 'Discovery', colors: { ...defaultTheme.colors, primary: '#97BC3B', secondary: '#729537' } };
export const nefirstTheme = { ...defaultTheme, name: 'NEFirst', colors: { ...defaultTheme.colors, primary: '#3F51AA', secondary: '#0F1D60' } };
export const gmbTheme = { ...defaultTheme, name: 'GMB Credit Union', colors: { ...defaultTheme.colors, primary: '#FF6A13', secondary: '#20A1A1' } };
export const natwestTheme = { ...defaultTheme, name: 'Natwest', colors: { ...defaultTheme.colors, primary: '#1D7B8A', secondary: '#42155E' } };
export const bailliestonTheme = {
  ...defaultTheme,
  name: 'Baillieston',
  colors: { ...defaultTheme.colors, primary: '#0A1D86', secondary: '#9081C2' },
};
export const whebTheme = { ...defaultTheme, name: 'Wheb', colors: { ...defaultTheme.colors, primary: '#80AADA', secondary: '#532B62' } };
export const penileeTheme = { ...defaultTheme, name: 'Penilee', colors: { ...defaultTheme.colors, primary: '#2758C6', secondary: '#001E62' } };
export const fiveLampsTheme = { ...defaultTheme, name: 'Five Lamps', colors: { ...defaultTheme.colors, primary: '#922A8E', secondary: '#283891' } };
export const nhscuTheme = { ...defaultTheme, name: 'NHSCU', colors: { ...defaultTheme.colors, primary: '#a7ddf2', secondary: '#00A3E4' } };
export const ukriTheme = {
  ...defaultTheme,
  name: 'Ukri',
  fonts: { ...defaultTheme.fonts, bodyFonts: 'Roboto', headingFonts: 'Moderat' },
  fontWeights: { ...defaultTheme.fontWeights, headingFonts: 'bold' },
  colors: { ...defaultTheme.colors, textColor: '#2E2D62', primary: '#8a1a9b', secondary: '#2E2D62' },
};
export const pfpTheme = { ...defaultTheme, name: 'Places For People', colors: { ...defaultTheme.colors, primary: '#5e2eba', secondary: '#FFFFFF' } };
export const cwrtTheme = { ...defaultTheme, name: 'CWRT', colors: { ...defaultTheme.colors, primary: '#c11e2f', secondary: '#000' } };
export const holdfastTheme = { ...defaultTheme, name: 'Holdfast', colors: { ...defaultTheme.colors, primary: '#5e1761', secondary: '#4f851f' } };

export default {
  soarTheme,
  gcuTheme,
  wishawTheme,
  pioneerTheme,
  capitalTheme,
  discoveryTheme,
  nefirstTheme,
  gmbTheme,
  natwestTheme,
  bailliestonTheme,
  whebTheme,
  penileeTheme,
  ukriTheme,
  fiveLampsTheme,
  nhscuTheme,
  pfpTheme,
  cwrtTheme,
  holdfastTheme,
};
