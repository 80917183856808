import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import Auth from '@aws-amplify/auth';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_CORE_API_URL || 'https://dev.api.soar.tech/api',
  credentials: 'include',
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const {
      accessToken: { jwtToken: token },
    } = await Auth.currentSession();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  } catch (error) {
    return {
      headers: {
        ...headers,
        'x-soar-tenant': `${process.env.REACT_APP_AWS_USER_POOL_ID}:${process.env.REACT_APP_AWS_APP_CLIENT_ID}`,
      },
    };
  }
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line
    for (const error of graphQLErrors) {
      switch (error.extensions.code) {
        case 'UNAUTHENTICATED':
          Auth.signOut();
          if (!error.path || !error.path.includes('preAuthCheck')) window.location.reload();
          return;
        default:
      }
    }
  }
});

const link = authLink.concat(ApolloLink.from([errorLink, httpLink]));
const cache = new InMemoryCache({ dataIdFromObject: o => o.id || null });
const client = new ApolloClient({
  link,
  cache,
});

export default client;
